// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Bg from './bg'

import TableOfContents from '../table-of-contents/homepage-toc'
import '../table-of-contents/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "covers/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = () => (
  <div className="cover-page">
    <div className="container">
      <div className="title-container">
        <Bg />
        <div className="title">
          <h1>THE AURA HANDBOOK</h1>
          <h2>
            <span style={{ display: 'block', marginBottom: '0.31rem' }}>
              The Aura Network strives to empower the local economy by allowing
              community members to discover the abundance around them.
            </span>
            <span>Web Book by Dan Be and B</span>
          </h2>
        </div>
      </div>
    </div>
    <div className="container">
      <br />
      <p>
        The Aura Network is a collaborative project between Auroville, as
        represented by the Korean Pavilion, and the Science Walden Center that
        was initiated in February 2019. Since its commencement, the project has
        successfully completed four phases. In this web-book that is to be
        launched in March of 2022 we will document the Aura Project and present
        it for enthusiasts who might be interested to learn more about the
        project as well as for academics who might be interested to go deeper
        into the research and insights.
      </p>
    </div>
  </div>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
