// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import random from 'lodash/random'
import sample from 'lodash/sample'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Gradient */
const Gradient = () => (
  <Spring native reset from={{ o: 0 }} to={{ o: 1 }}>
    {(props) => (
      <animated.div style={{ opacity: props.o }}>
        <div className="gradient">
          <svg viewBox="0 0 3000 3000" preserveAspectRatio="xMidYMid slice">
            <defs>
              <radialGradient id="rg0" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ffd700" />
                <stop offset="100%" stopColor="#ffd700" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg0" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ffd700" />
                <stop offset="100%" stopColor="#ffd700" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg0" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ffd700" />
                <stop offset="100%" stopColor="#ffd700" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg1" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ff8b4e" />
                <stop offset="100%" stopColor="#ff8b4e" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg1" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ff8b4e" />
                <stop offset="100%" stopColor="#ff8b4e" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg1" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#ff8b4e" />
                <stop offset="100%" stopColor="#ff8b4e" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg2" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#fff69c" />
                <stop offset="100%" stopColor="#fff69c" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg2" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#fff69c" />
                <stop offset="100%" stopColor="#fff69c" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg2" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#fff69c" />
                <stop offset="100%" stopColor="#fff69c" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg3" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#FFA50F" />
                <stop offset="100%" stopColor="#FFA50F" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg3" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#FFA50F" />
                <stop offset="100%" stopColor="#FFA50F" stopOpacity="0" />
              </radialGradient>
              <radialGradient id="rg3" fx={random(0.3, 0.4, true)} fy="0.5">
                <stop offset="0%" stopColor="#FFA50F" />
                <stop offset="100%" stopColor="#FFA50F" stopOpacity="0" />
              </radialGradient>
            </defs>
            <rect id="bg" x="0" y="0" width="100%" height="100%" />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(
                0,
                360,
                true
              )}) translate(-285.9748196495925 -472.7925181184045) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
            <rect
              className={`rect ${sample(['rect0', 'rect1', 'rect2', 'rect3'])}`}
              x="0"
              y="0"
              width="100%"
              height="100%"
              transform={`translate(1500 1500) scale(${random(
                0.8,
                1.2,
                true
              )} ${random(0.8, 1.2, true)}) skewX(${random(
                -45,
                45,
                true
              )}) rotate(${random(0, 360, true)}) translate(${random(
                -1500,
                1500,
                true
              )} ${random(-1500, 1500, true)}) translate(-1500 -1500)`}
            />
          </svg>
        </div>
      </animated.div>
    )}
  </Spring>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Gradient
