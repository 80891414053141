// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { graphql } from 'gatsby'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import CoverPage from '../components/cover-page'
import '../components/cover-page/style.less'

import BookSchema from '../components/schema/book-schema'
import ProductSchema from '../components/schema/product-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract:
    'The Aura Network is a collaborative project between Auroville, as represented by the Korean Pavilion, and the Science Walden Center that was initiated in February 2019. Since its commencement, the project has successfully completed four phases. In this web-book that is to be launched in March of 2022 we will document the Aura Project and present it for enthusiasts who might be interested to learn more about the project as well as for academics who might be interested to go deeper into the research and insights.',
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     cover: file(relativePath: { eq: "cover.jpg" }) {
//       ...max3000
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="home-page" pageSchema={pageSchema} {...props}>
    <BookSchema
      data={{
        name: 'THE AURA HANDBOOK',
        description:
          'The Aura Network is a collaborative project between Auroville, as represented by the Korean Pavilion, and the Science Walden Center that was initiated in February 2019. Since its commencement, the project has successfully completed four phases. In this web-book that is to be launched in March of 2022 we will document the Aura Project and present it for enthusiasts who might be interested to learn more about the project as well as for academics who might be interested to go deeper into the research and insights.',
      }}
    />
    <ProductSchema
      data={{
        name: 'THE AURA HANDBOOK',
        description:
          'The Aura Network is a collaborative project between Auroville, as represented by the Korean Pavilion, and the Science Walden Center that was initiated in February 2019. Since its commencement, the project has successfully completed four phases. In this web-book that is to be launched in March of 2022 we will document the Aura Project and present it for enthusiasts who might be interested to learn more about the project as well as for academics who might be interested to go deeper into the research and insights.',
      }}
    />
    <CoverPage />
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
